import { Typography } from "@alfalab/core-components/typography";
import { Button } from "@alfalab/core-components/button";
import { Space } from "@alfalab/core-components/space";

import styles from "./InfoPage.module.css";

export type InfoPageProps = {
	icon: React.ReactNode;
	title: string;
	message?: string;
	actions: Array<{
		view?: "primary" | "secondary";
		label: string;
		callback(): void;
	}>;
};

export const InfoPage: React.FC<InfoPageProps> = ({
	icon,
	title,
	message,
	actions,
}) => {
	return (
		<div className={styles["container"]}>
			<Space size="l" className={styles["content"]}>
				{icon}

				<Space align="center" size={"s"}>
					<Typography.TitleMobile tag="h2" view="small" weight="medium">
						{title}
					</Typography.TitleMobile>

					{message && (
						<Typography.Text view="primary-small">{message}</Typography.Text>
					)}
				</Space>
			</Space>

			<Space size="m" fullWidth>
				{actions.map((action, idx) => (
					<Button
						key={idx.toString()}
						block
						view={action.view || "primary"}
						size="m"
						onClick={action.callback}
					>
						{action.label}
					</Button>
				))}
			</Space>
		</div>
	);
};
