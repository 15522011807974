import { detectPlatform, Platform } from "@/shared/utils/index";
import { logger } from "@/shared/logger";

let pageId = 0;
let pageIdCorrection: number | undefined;

function getPageId(pageId: number, options?: { withoutCorrection?: boolean }) {
	if (pageIdCorrection === undefined) {
		pageIdCorrection = options?.withoutCorrection ? pageId : pageId - 1;
	}

	return pageId;
}

const DEFAULT_PAGE_TITLE = "Афиша";

type PageSettings = {
	pageId?: number;
	pageTitle?: string;
	withoutCorrection?: boolean;
};

export function setPageSettings(args: PageSettings) {
	const platform = detectPlatform();
	const pageTitle = args.pageTitle || DEFAULT_PAGE_TITLE;

	pageId = args.pageId
		? getPageId(args.pageId, { withoutCorrection: args.withoutCorrection })
		: pageId;

	logger.info("setPageSettings", {
		pageTitle,
		pageId,
	});

	if (platform === Platform.ANDROID) {
		window.Android?.setPageSettings(
			JSON.stringify({
				pageTitle,
				pageId,
			}),
		);
	} else if (platform === Platform.IOS) {
		const pageTitleStr = `?pageTitle=${encodeURIComponent(pageTitle)}`;
		const pageIdStr = pageId > 1 ? `&pageId=${pageId}` : "";
		const paramsToSend = `ios:setPageSettings/${pageTitleStr + pageIdStr}`;

		window.location.replace(paramsToSend);
	}
}
