import { isSameDay, isSameYear } from "date-fns";
import { formatDateTime } from "@/shared/utils/formatDate";

export function formatActionDate(args: {
	firstDate: Date;
	lastDate: Date;
	time: string;
}): string {
	const start = args.firstDate;
	const end = args.lastDate;
	const now = new Date();

	if (isSameDay(start, end) || start.getTime() > end.getTime()) {
		return `${formatDateTime(start, {
			month: "long",
			year: !isSameYear(start, now) ? "numeric" : undefined,
			day: "numeric",
		})} в ${args.time}`;
	}

	const startDate = formatDateTime(start, {
		month: "long",
		year: !isSameYear(start, now) ? "numeric" : undefined,
		day: "numeric",
	});

	const endDate = formatDateTime(end, {
		month: "long",
		year: !isSameYear(end, now) ? "numeric" : undefined,
		day: "numeric",
	});

	return `${startDate} — ${endDate}`;
}
