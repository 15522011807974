import { API_ENDPOINT } from "../../constants";

import { post } from "../fetch";
import { getApiKey } from "../auth";

export enum TicketStatusEnum {
	UNUSED = 0,
	IN = 1,
	OUT = 2,
	REFUNDED = 3,
	REFUNDING = 4,
	PASS = 5,
}

export type Ticket = {
	ticketId: number;
	ticketIdAlt: number;
	price: number;
	totalPrice: number;
	serviceCharge: number;
	barCodeNumber: string;
	barCodeType: string;
	statusInt: number;
	statusStr: string;
	categoryName?: string;
	sector?: string;
	row?: string;
	number?: string;
};

export type GetTicketsByOrderResult = {
	orderId: number;
	orderIdAlt: string;
	created: string;
	currency: string;
	discount: number;
	serviceCharge: number;
	sum: number;
	totalSum: number;
	cashback: number;
	quantity: number;
	status: string;
	cityId: number;
	cityName: string;
	venueId: number;
	venueName: string;
	venueAddress: string;
	actionId: number;
	actionName: string;
	actionEventId: number;
	smallPosterUrl: string;
	eventTime: string;
	age: string;
	ticketList: Array<Ticket>;
	fanIdRequired: boolean;
	user: {
		email: string;
		name?: string;
		phone?: string;
	};
};

export async function getTicketsByOrder(args: { oid: number | string }) {
	return await post<GetTicketsByOrderResult>("/get_tickets_by_order", args);
}

export function getTicketPDFUrl(args: { oid: number; tid?: number }) {
	const api_key = getApiKey();

	if (api_key) {
		const url = new URL(
			`${API_ENDPOINT}/tickets${args.tid ? "/pdf" : "/order"}`,
		);

		url.searchParams.append("oid", String(args.oid));

		if (args.tid) {
			url.searchParams.append("tid", String(args.tid));
		}

		url.searchParams.append("api_key", api_key);

		return url.toString();
	}

	throw new Error("Не указан API ключ");
}
