import { useEffect } from "react";
import {
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import { ENV, SENTRY_DSN } from "./constants";

export type ErrorType = "warning" | "message";

export class APIError extends Error {
	code: string;
	message: string;
	advice: string;
	system: string;
	type: "warning" | "message";
	error: number | string;

	constructor(config: {
		code: string;
		message: string;
		advice: string;
		system: string;
		type?: ErrorType;
		error: number;
	}) {
		super();

		this.code = config.code;
		this.advice = config.advice;
		this.system = config.system;
		this.message = config.message || "Произошла ошибка";
		this.type = config.type || "warning";
		this.error = config.error;
	}

	toJSON() {
		return JSON.stringify({
			code: this.code,
			advice: this.advice,
			system: this.system,
			message: this.message,
			type: this.type,
			error: this.error,
		});
	}
}

export class NetworkError extends Error {
	constructor(message: string) {
		super();
		this.message = message;
	}
}

export function initErrorTracker() {
	if (ENV === "prod") {
		Sentry.init({
			dsn: SENTRY_DSN,
			integrations: [
				Sentry.reactRouterV6BrowserTracingIntegration({
					useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes,
				}),
			],
			environment: ENV === "prod" ? "production" : "staging",
		});
	}
}

export function captureException(error: Error) {
	if (error instanceof APIError) {
		const errorPayload = error.toJSON();

		if (error.type === "warning") {
			const errorToSentry = new Error(`fetch error: ${errorPayload}`);
			errorToSentry.name = error.system;

			Sentry.captureException(errorToSentry, (scope) => {
				scope.setTransactionName(location.pathname);
				return scope;
			});
		}
	} else if (error instanceof NetworkError) {
		return;
	} else {
		Sentry.captureException(error);
	}
}
