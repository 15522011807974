import { useEffect } from "react";
import { SuperEllipse } from "@alfalab/core-components/icon-view/super-ellipse";
import { ScrewdriverWrenchBoldMIcon } from "@alfalab/icons-glyph/ScrewdriverWrenchBoldMIcon";

import { trackScreenView } from "@/shared/metrica";

import { InfoPage, type InfoPageProps } from "../info-page";

export const ErrorPage: React.FC<
	Pick<InfoPageProps, "actions"> & {
		icon?: React.ReactNode;
		title?: string;
		message?: string;
	}
> = ({ icon, title, message, actions }) => {
	useEffect(() => {
		trackScreenView("Error block", document.title, {
			content: message,
		});
	}, [message]);

	return (
		<InfoPage
			icon={
				<SuperEllipse size={80}>
					{icon ?? <ScrewdriverWrenchBoldMIcon color="black" />}
				</SuperEllipse>
			}
			title={title || "Произошла ошибка"}
			message={message}
			actions={actions}
		/>
	);
};
