export const MAX_CITIES_HISTORY_ITEMS = 5;
export const MAX_PLACES_HISTORY_ITEMS = 5;
export const MAX_SEARCH_RESULTS = 50;
export const MAX_POPULAR_PLACES = 15;

export const ENV =
	typeof process !== "undefined"
		? (process.env.PUBLIC_DEPLOY_ENV as "test" | "prod")
		: "prod";

export const API_ENDPOINT = {
	test: "https://api-alfa-test.kassir.ru",
	prod: "https://api-alfa.kassir.ru",
}[ENV];

export const SENTRY_DSN =
	"https://3749b9af2f833f0424cc7fbb7b22bc17@o4508212121108480.ingest.de.sentry.io/4508212123336784";
