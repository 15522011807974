import { post } from "../fetch";

export type SelectionEvent = {
	actionId: string;
	age: string;
	cashback: number;
	cityId: string;
	countryId: string;
	dates: string;
	eventId: number;
	id: number;
	name: string;
	popularity: number;
	poster: string;
	price: { min: number; max: number };
	type: string;
	venueId: string;
};

export type GetSelectionResult = {
	countryId: number;
	countryName: string;
	cityId: number;
	cityName: string;
	parentCityId: number;
	selection: {
		id: number;
		name: string;
		short_name: string;
		rank: number;
		events: SelectionEvent[];
	};
	new_orders: {
		orders: number;
		time: number;
	};
};

export function getSelection(args: { cid: number | string; sid: number }) {
	return post<GetSelectionResult>("/get_selection", args);
}
